<template>
  <div>
    <NavBar
      style="background-color: #fff"
      :bool="true"
      @navCallback="navCallback"
    >
      <div slot="title">用户评价</div>
    </NavBar>
    <!-- 有评价的显示 -->
    <div v-if="true">
      <!-- <div class="label">
        <div class="all">全部</div>
        <div class="label-class">不伤手（2）</div>
      </div> -->
      <!-- 评价具体内容 -->
      <div class="evaluation-layout">
        <div>
          <div class="evaluation-header">
            <div class="title">宝贝评价（{{ comment.comment_num }}）</div>
          </div>
        </div>
        <!-- 有评价 -->
        <div>
          <div
            class="evaluation-info"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="user-info">
              <div class="head-portrait">
                <img :src="item.head_img" />
              </div>
              <div class="user-name">
                <div>
                  <span>{{ item.nickname }}</span>
                  <van-rate v-model="item.grade" size="14" readonly />
                </div>
                <span>{{ item.created_date }}</span>
              </div>
            </div>
            <div>
              <div class="evaluation-content">
                <span>
                  {{ item.content }}
                </span>
              </div>
              <div class="img-layout">
                <div
                  class="evaluation-img"
                  v-for="(img, img_index) in item.goods_imgs"
                  :key="img_index"
                >
                  <img
                    :src="img"
                    alt=""
                    @click="imgPreview(item.goods_imgs, img_index)"
                    :data-index="img_index"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.lenght <= 0"></div>
      </div>
    </div>
    <!-- 无评价的显示 -->
    <div class="no-evaluation" v-else>
      <div class="position">
        <img class="no-img" src="~@/assets/common/no_evaluation_icon_is_available.png" alt="" />
        <div>暂无评价</div>
      </div>
    </div>
    <!-- <template v-if="currentIndex">
      <PreviewSwipe
        :listData="currentList"
        :showImgPreview="showImgPreview"
        :currentIndex="currentIndex * 1"
        @clickOverlay="clickOverlay"
      ></PreviewSwipe>
    </template> -->
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import NavBar from "@/layout/nav-bar/nav-bar";
// import PreviewSwipe from "./preview-swipe";
import { getCommentList, getGoodsTag } from "@/api/goods.js";
export default {
  name: "comments-page",
  components: {
    // PreviewSwipe,
    NavBar,
  },
  props: ["goodsId"],
  data() {
    return {
      goodId: "",
      list: [],
      comment: {
        tags: [],
        comment_num: 0,
      },
      // showImgPreview: false, //显示图片预览开关
      // currentList: [], // 当前列表
      // currentIndex: "", // 当前触发事件img索引
    };
  },
  mounted() {
    this.getGoodsTag();
    this.getCommentList();
    this.goodId = this.$route.query.goodsId || this.goodsId;
  },
  methods: {
    imgPreview(imgAarr, index) {
      // 图片预览
      ImagePreview({
        images: imgAarr,
        startPosition: index,
      });
    },
    navCallback() {
      this.$emit("closeComments");
    },
    async getCommentList() {
      const ret = await getCommentList({
        goods_id: this.goodsId,
        page_num: 3,
      });
      if (ret.code * 1 === 1) {
        this.list = ret.data.items;
      }
    },
    async getGoodsTag() {
      const ret = await getGoodsTag({ goods_id: this.goodsId });
      if (ret.code * 1 === 1) {
        this.comment = ret.data;
      }
    },
    // 组件预览图片开关
    // previewImg(item, e) {
    //   this.currentIndex = e.target.dataset.index;
    //   this.showImgPreview = true;
    //   this.currentList = item;
    // },
    // clickOverlay() {
    //   this.showImgPreview = false;
    //   this.currentIndex = "";
    // },
  },
};
</script>

<style lang="less" scoped>
.label {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  .all {
    padding: 2px 10px;
    color: #ed301d;
    border-radius: 18px;
    font-size: 12px;
    border: 1px solid #ed301d;
  }
  .label-class {
    font-size: 12px;
    text-align: center;
    padding: 4px 10px;
    margin-left: 10px;
    background-color: #f9f9f9;
    color: #6f6f6f;
    border-radius: 18px;
  }
}
.no-evaluation {
  position: relative;
  margin-top: 220px;
}
.position {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .no-img {
    width: 220px;
    height: 130px;
  }
}
</style>
<style lang="less" scoped>
.evaluation-layout {
  background-color: #fff;
  border-radius: 0 0 16px 16px;
  padding: 12px;
}
.evaluation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-size: 16px;
    font-weight: 600;
  }
}
.evaluation-info {
  margin-top: 12px;
  .user-info {
    display: flex;
    justify-content: start;
    align-items: center;
    .head-portrait {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .user-name {
      margin-left: 10px;
      div {
        font-weight: 600;
      }
    }
  }
  .evaluation-content {
    margin-top: 16px;
    font-size: 14px;
    color: #6f6f6f;
  }
  .img-layout {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 12px;
    .evaluation-img {
      width: 116px;
      padding: 6px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
